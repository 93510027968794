<template>
  <div>
    <van-row class="navbar-all" v-if="type === 'ALL'">
      <van-col span="4" @click="back()"
        ><div class="back-btn-left">
          <van-icon name="arrow-left" /> 返回
        </div></van-col
      >
      <van-col span="16" class="title color-s1"
        >关注公众号享受更多服务与优惠</van-col
      >
      <van-col span="4"
        ><div class="back-btn-right" @click="focusWpp()">
          <van-icon name="aim" /> 关注
        </div></van-col
      >
    </van-row>
    <div class="fill"></div>
    <van-row class="navbar-smp" v-if="type === 'SMP'">
      <van-col span="2" class="left-icon" @click="back"
        ><van-icon name="arrow-left" size="22"
      /></van-col>
    </van-row>
  </div>
</template>
<script>
import { Icon, NavBar } from 'vant'
export default {
  components: {
    [Icon.name]: Icon,
    [NavBar.name]: NavBar
  },
  data () {
    return {
      type: 'ALL',
      backUrl: ''
    }
  },
  props: {
    title: { type: String },
    callback: Function
  },
  mounted () {
    // var app = window.sessionStorage.getItem(this.SESSION_APP)
    document.title = this.title
    this.backUrl = window.history.state.back
  },
  methods: {
    back () {
      if (this.callback === undefined) {
        window.location.href = this.backUrl
      } else {
        this.callback()
      }
    },
    focusWpp () {
      this.$router.push({ path: '/mde/focus' })
    }
  }
}
</script>
<style lang="less" scoped>
.navbar-all {
  z-index: 100;
  position: absolute;
  position: fixed;
  top: 0px;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  box-shadow: 0px 1px 3px #eee;
  width: 100%;
  .back-btn-left {
    font-size: 14px;
    font-weight: 600;
    height: 43px;
    line-height: 43px;
    border-right: 1px solid #eee;
  }
  .back-btn-right {
    font-size: 14px;
    font-weight: 600;
    height: 43px;
    line-height: 43px;
    border-left: 1px solid #eee;
  }
  .title {
    font-size: 14px;
    height: 43px;
    line-height: 43px;
    font-weight: 600;
  }
}
.fill {
  height: 43px;
}
.navbar-smp {
  z-index: 100;
  position: absolute;
  position: fixed;
  top: 5px;
  left: 5px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  width: 28px;
  height: 28px;
  border-radius: 15px;
  .left-icon {
    text-align: center;
    padding: 3px 0px 0px 2px;
    height: 28px;
    line-height: 28px;
    color: #fff;
  }
}
</style>
