<template>
  <div></div>
</template>
<script>
export default {
  data () {
    return {
      orderCode: ''
    }
  },
  props: {
    moduleType: { type: String },
    moduleLabel: { type: String }
  },
  mounted () {
  },
  methods: {
    async createLog () {
      var target = window.location.href
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
      var platform = window.sessionStorage.getItem(this.SESSION_PLATFORM)
      var app = window.sessionStorage.getItem(this.SESSION_APP)
      var source = window.sessionStorage.getItem(this.SESSION_SOURCE)
      var ip = window.sessionStorage.getItem(window.const.global.APP_CUSTOMER_IP)
      var imsi = window.sessionStorage.getItem(window.const.global.APP_CUSTOMER_IMSI)
      var location = window.sessionStorage.getItem(window.const.global.APP_CUSTOMER_LOCATION)
      if (operator !== null && operator !== undefined && operator !== '') {
        var pd = {
          moduleType: this.moduleType,
          moduleLabel: this.moduleLabel,
          operatorCode: operator,
          sellerCode: seller,
          refereeCode: referee,
          source: source,
          platformKey: platform,
          appKey: app,
          target: target,
          ip: ip,
          imsi: imsi,
          location: location
        }
        const { data: res } = await this.$http.post(this.BMS_URL + '/log/visit/createVisit', this.$qs.stringify(pd))
        if (res.status !== '200') {
          console.log(res.msg)
        }
      }
    }
  }
}
</script>
