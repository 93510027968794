import { Search, Icon, Swipe, SwipeItem, List, Image } from 'vant'
import Login from '../../../module/intercept/Login.vue'
import Navbar from '../../../module/common/Navbar.vue'
import Share from '../../../module/common/Share.vue'
import Loading from '../../../module/common/Loading.vue'
import LogVisit from '../../../module/common/LogVisit.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Navbar: Navbar,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [List.name]: List,
        [Image.name]: Image
    },
    data () {
        return {
            loadingShow: false,
            finished: false,
            chatCode: '',
            actionKey: '',
            page: { current: 0, size: 20, last: 1, lock: false },
            itemList: []
        }
    },
    mounted () {
        var query = this.$route.query
        this.chatCode = query.chatCode
        this.actionKey = query.actionKey
        this.retrieveItemList()
        this.$refs.share.default('main')
    },
    methods: {
        search () {
            this.page.current = 0
            this.finished = false
            this.itemList = []
            this.retrieveItemList()
        },
        async retrieveItemList () {
            if (!this.finished && !this.page.lock) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                this.page.lock = true
                if (this.page.current > this.page.last) {
                    this.finished = true
                } else {
                    var pd = { chatCode: this.chatCode, actionKey: this.actionKey, current: this.page.current, size: this.page.size }
                    var { data: res } = await this.$http.post(this.BMS_URL + '/helper/chat/retrieveChatItemList', this.$qs.stringify(pd))
                    if (res.status === '200') {
                        console.log(res.data)
                        this.itemList = this.itemList.concat(res.data)
                        this.page.last = res.page.last
                        this.page.lock = false
                    }
                }
                this.loadingShow = false
            }
        },
        detail (item) {
            if (this.actionKey === 'RCT_RECRUIT_SIFT') {
                window.sessionStorage.setItem(this.SESSION_BACKURL, window.location.href)
                this.$router.push({ path: '/recruit/consult', query: { taskCode: item.code } })
            } else if (this.actionKey === 'IFM_INFORM_SIFT') {
                this.$router.push({ path: '/mde/inform/detail', query: { code: item.code } })
            }
        },
        back () {
            this.$router.push({ path: '/mde/helper/home' })
        }
    }
}
