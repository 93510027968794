<template>
  <div></div>
</template>
<script>
export default {
  data () {
    var at = this.mode + '-' + this.active
    return {
      activeTab: at,
      platform: 'WPP',
      app: 'WPP_LHA'
    }
  },
  props: { backUrl: { type: String }, callback: { type: Function }, mode: { type: String } },
  mounted () {
    window.sessionStorage.setItem(this.SESSION_PLATFORM, this.platform)
    window.sessionStorage.setItem(this.SESSION_APP, this.app)
  },
  methods: {
    validate () {
      var url = ''
      if (this.backUrl !== undefined && this.backUrl !== null) {
        url = this.backUrl
      } else {
        url = window.location.href
      }
      var query = this.$route.query
      var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
      if (token !== null && token !== '' && token !== undefined) {
        this.modeValidate()
      } else {
        var code = query.code
        var referee = query.referee
        var refereeType = query.refereeType
        var operator = query.operator
        var seller = query.seller
        var site = query.site
        var source = query.source
        var sourceType = query.sourceType
        var platform = query.platform
        var app = query.app
        if (referee !== null && referee !== '' && referee !== undefined) {
          window.sessionStorage.setItem(this.SESSION_REFEREE, referee)
        } else {
          var refereeEmp = window.sessionStorage.getItem(this.SESSION_REFEREE)
          if (refereeEmp === null || refereeEmp === '' || refereeEmp === undefined) {
            window.sessionStorage.setItem(this.SESSION_REFEREE, '1')
          }
        }
        if (refereeType !== null && refereeType !== '' && refereeType !== undefined) {
          window.sessionStorage.setItem(this.SESSION_REFEREE_TYPE, refereeType)
        }
        if (operator !== null && operator !== '' && operator !== undefined) {
          window.sessionStorage.setItem(this.SESSION_OPERATOR, operator)
        } else {
          var operatorEmp = window.sessionStorage.getItem(this.SESSION_OPERATOR)
          if (operatorEmp === null || operatorEmp === '' || operatorEmp === undefined) {
            window.sessionStorage.setItem(this.SESSION_OPERATOR, '1')
          }
        }
        if (seller !== null && seller !== '' && seller !== undefined) {
          window.sessionStorage.setItem(this.SESSION_SELLER, seller)
        } else {
          var sellerEmp = window.sessionStorage.getItem(this.SESSION_SELLER)
          if (sellerEmp === null || sellerEmp === '' || sellerEmp === undefined) {
            window.sessionStorage.setItem(this.SESSION_SELLER, '1')
          }
        }
        if (site !== null && site !== '' && site !== undefined) {
          window.sessionStorage.setItem(this.SESSION_SITE, site)
        }
        if (source !== null && source !== '' && source !== undefined) {
          window.sessionStorage.setItem(this.SESSION_SOURCE, source)
        }
        if (sourceType !== null && sourceType !== '' && sourceType !== undefined) {
          window.sessionStorage.setItem(this.SESSION_SOURCE_TYPE, sourceType)
        }
        if (platform !== null && platform !== '' && platform !== undefined) {
          this.platform = platform
        }
        if (app !== null && app !== '' && app !== undefined) {
          this.app = app
        }
        window.sessionStorage.setItem(this.SESSION_PLATFORM, this.platform)
        window.sessionStorage.setItem(this.SESSION_APP, this.app)
        if (code !== '' && code !== undefined && code !== null) {
          this.code = code
          this.login()
        } else {
          var appid = ''
          if (this.app === 'WPP_MOC') {
            appid = 'wxa8145a74e5f2b39a'
          } else if (this.app === 'WPP_LHA') {
            appid = 'wx0ae3d729b83399a8'
          }
          var lhUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + this.replaceUrl(url) + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
          window.location.href = lhUrl
        }
      }
    },
    async login () {
      var pd = { code: this.code, platform: this.platform, app: this.app }
      var { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/loginBind', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_TOKEN, res.data.token)
        window.sessionStorage.setItem(this.SESSION_USER, res.data.userCode)
        window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
        this.retrieveSeller(res.data.userCode)
      } else if (res.code === '4002') {
        window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
      }
      this.retrieveWPPUserInfo(res.data.openid)
    },
    async retrieveWPPUserInfo (openid) {
      var pd = { app: 'WPP_MOC', openid: openid }
      var { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveWPPUserInfo', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_SUBSCRIBE, res.data.subscribe)
      } else {
        window.sessionStorage.setItem(this.SESSION_SUBSCRIBE, '0')
      }
    },
    async retrieveSeller (userCode) {
      var pd = { userCode: userCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/retrieveUser', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_SELLER, res.data.sellerCode)
        window.sessionStorage.setItem(this.SESSION_REFEREE, userCode)
      }
      this.modeValidate()
    },
    modeValidate () {
      var userCode = window.sessionStorage.getItem(this.SESSION_USER)
      if (this.mode !== 'GST' && userCode === '0') {
        this.$router.push({ path: '/mde/bind' })
      } else {
        this.callback()
      }
    },
    replaceUrl (url) {
      url = url.replaceAll(':', '%3A')
      url = url.replaceAll('/', '%2F')
      url = url.replaceAll('?', '%3F')
      url = url.replaceAll('=', '%3D')
      url = url.replaceAll('&', '%26')
      url = url.replaceAll('+', '%2B')
      url = url.replaceAll(' ', '%20')
      url = url.replaceAll('#', '%23')
      return url
    }
  }
}
</script>
<style lang="less" scoped>
</style>
