<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <Navbar title="明细项目" type="SMP" :callback="back" />
    <van-row>
      <van-col span="24">
        <van-image
          class="focus-img"
          :src="require('../../../../assets/images/recruit/ai-guide.jpg')"
        ></van-image>
      </van-col>
    </van-row>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 明细列表</van-col
        >
      </van-row>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        class="items"
        :immediate-check="false"
        @load="retrieveItemList"
      >
        <template #default>
          <div
            class="item"
            v-for="item in itemList"
            :key="item.code"
            @click="detail(item)"
          >
            <div class="name text-short">· {{ item.text }}</div>
          </div>
        </template>
      </van-list>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="CNT" module="CNT_INDEX" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
