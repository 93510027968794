<template>
  <van-popup :show="show" style="background-color: transparent"
    ><van-loading size="24px" :color="COLOR_M" vertical>加载中...</van-loading>
  </van-popup>
</template>
<script>
import { Popup, Loading } from 'vant'
export default {
  components: {
    [Popup.name]: Popup,
    [Loading.name]: Loading
  },
  data () { return {} },
  props: { show: { type: Boolean } },
  methods: {
  }
}
</script>
<style lang="less" scoped>
</style>
